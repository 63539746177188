import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_APP_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public';

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,

	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	sendDefaultPii: false,
	environment: PUBLIC_APP_ENV,
	enabled: PUBLIC_APP_ENV !== 'local'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
