import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/v1": [6,[2],[3]],
		"/v1/__components": [7,[2],[3]],
		"/v1/__pages": [8,[2],[3]],
		"/v1/__pages/surveys/loader": [9,[2],[3]],
		"/v1/dynamic_profile": [10,[2],[3]],
		"/v1/dynamic_profile/completed": [11,[2],[3]],
		"/v1/dynamic_profile/not_qualify": [12,[2],[3]],
		"/v1/dynamic_profile/quals": [13,[2],[3]],
		"/v1/error": [14,[2],[3]],
		"/v1/profile/completed": [15,[2,4],[3]],
		"/v1/profile/notice": [16,[2,4],[3]],
		"/v1/profile/pledge": [17,[2,4],[3]],
		"/v1/profile/quals": [~18,[2,4],[3]],
		"/v1/profile/quals/prev": [~19,[2,4],[3]],
		"/v1/profile/setup": [20,[2,4],[3]],
		"/v1/surveys": [~21,[2],[3]],
		"/v1/surveys/bounced": [~23,[2],[3]],
		"/v1/surveys/check_availability": [~24,[2],[3]],
		"/v1/surveys/completed": [~25,[2],[3]],
		"/v1/surveys/daily_limit": [26,[2],[3]],
		"/v1/surveys/hourly_limit": [27,[2],[3]],
		"/v1/surveys/match_error": [28,[2],[3]],
		"/v1/surveys/not_available": [29,[2],[3]],
		"/v1/surveys/not_qualify": [30,[2],[3]],
		"/v1/surveys/rejected": [~31,[2],[3]],
		"/v1/surveys/terminated": [~32,[2],[3]],
		"/v1/surveys/thank_you": [33,[2],[3]],
		"/v1/surveys/[id]": [~22,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';